










import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Checkbox extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private label!: string;

  @Prop({
    type: Boolean,
    required: false
  })
  private value!: false;

  public get attrs() {
    return {
      ...this.$attrs,
      value: this.value,
      'off-icon': '$checkbox',
      'on-icon': '$checkboxChecked',
      ripple: false
    };
  }
}
