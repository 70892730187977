






















import {Vue, Component, Prop} from 'vue-property-decorator';

export interface IFooterNavigation {
  title?: string;
  link?: string;
  text?: string;
  rel?: string;
}

@Component
export default class FooterNavigation extends Vue {
  @Prop({type: String, required: false, default: ''})
  public title!: string;

  @Prop({type: Array, required: true})
  public listItem!: IFooterNavigation[];

  @Prop({type: String, required: false, default: ''})
  public rel!: string;
}
