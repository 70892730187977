import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import CmsApiService, {
  CmsRoute,
  CmsSingleType,
  ICmsQueryParams,
  ICmsResponse, ICmsResponseAdminUser,
  ICmsResponseImage
} from '@/services/api/cms.service';
import {i18n} from '@/main';
import {Language, ValueOf} from '@/interfaces';
import {cloneObject} from '@/utilities';
import router from '@/router';
import { LocaleService } from '@/utilities/locale';

export interface ICmsPage extends ICmsResponse {
  identifier: string;
  title: string;
  subtitle: string;
  content: HTMLElement;
}

export interface ICmsFAQ extends ICmsResponse {
  title: string;
  content: HTMLElement;
}

export interface ICmsFAQ extends ICmsResponse {
  title: string;
  content: HTMLElement;
}

export interface ICmsBlogTag extends ICmsResponse {
  tag: string;
  posts?: ICmsBlogPost[];
}

export interface ICmsBlogPost extends ICmsResponse {
  images: ICmsResponseImage[];
  title: string;
  content: HTMLElement;
  description: string;
  tags?: ICmsBlogTag[];
  admin_user?: Partial<ICmsResponseAdminUser>;
}

export interface ICmsImprintPage extends ICmsResponse {
  leftContent: string;
  rightContent: string;
  content: string;
}

export interface ICmsPerksCard {
  buttonText: string;
  createdAt: string;
  id: string;
  image: ICmsResponseImage;
  locale: Language;
  localizations: string;
  logo: ICmsResponseImage;
  published_at: string;
  text: string;
  updatedAt: string;
  url: string;
}

export enum CmsPageIdentifier {
  PRIVACY = 'privacy',
  FAQ = 'faq',
  INFORMATION = 'information',
  BLOG = 'blog',
  TERMS = 'terms',
  PERKS = 'perks',
}

export interface ICmsState {
  cmsPages: ICmsPage[];
  cmsImprint: ICmsImprintPage | null;
  cmsFAQTenant: ICmsFAQ[];
  cmsFAQLandlord: ICmsFAQ[];
  cmsBlogPosts: ICmsBlogPost[];
  cmsBlogPost: Partial<ICmsBlogPost>;
  cmsPerksPosts: Partial<ICmsPerksCard>;
}

export const getBlogImageUrl = (post: ICmsBlogPost) => {
  return post && post.images
    ? post.images.length && post.images[0].url
    : null;
};

export const getPerksImageUrl = (post: ICmsPerksCard) => {
  return post && post.image
    ? post.image && post.image.url
    : null;
};

export const getBlogTags = (post: ICmsBlogPost) => {
  return post && post.tags
    ? post.tags.map((item) => `#${item.tag}`)
    : null;
};

const initialState = (): ICmsState => ({
  cmsPages: [],
  cmsImprint: null,
  cmsFAQTenant: [],
  cmsFAQLandlord: [],
  cmsBlogPosts: [],
  cmsBlogPost: {},
  cmsPerksPosts: {},
});
const state = initialState();

const getters = {
  cmsQueryParams: () => ({
    '_locale': LocaleService.getSeparatedByCode(i18n.locale).lang
  }),
  cmsPage: (state: ICmsState) => {
    return (identifier: ValueOf<CmsPageIdentifier>) => state.cmsPages
      ? state.cmsPages.filter((item: ICmsPage) => {
          return item.identifier === identifier;
        })[0] || []
      : [];
  },
  cmsImprint: (state: ICmsState) => state.cmsImprint,
  cmsFAQTenant: (state: ICmsState) => state.cmsFAQTenant,
  cmsFAQLandlord: (state: ICmsState) => state.cmsFAQLandlord,
  cmsBlogPosts: (state: ICmsState) => cloneObject(state.cmsBlogPosts) || [],
  cmsBlogPostsWithoutLastPost: (state: ICmsState, getters) => {
    return getters.cmsBlogPosts.length
      ? getters.cmsBlogPosts.slice(0, -1)
      : [];
  },
  cmsBlogLastPost: (state: ICmsState, getters) => {
    return getters.cmsBlogPosts.length
      ? getters.cmsBlogPosts[getters.cmsBlogPosts.length - 1]
      : null;
  },
  cmsBlogPost: (state: ICmsState) => {
    return Object.keys(state.cmsBlogPost).length
      ? state.cmsBlogPost
      : null;
  },
  cmsBlogRelatedPosts: (state: ICmsState, getters) => {
    const { cmsBlogPosts, cmsBlogPost } = getters;

    if (!cmsBlogPost) { return cmsBlogPosts; }

    const {
      id: currentId = null,
      tags: currentTags = []
    } = cmsBlogPost;

    const relatedTags = currentTags.map((item) => item.tag);
    return cmsBlogPosts.filter((post) => {
      if (post.id !== currentId) {
        return post.tags.some((item) => {
          return relatedTags.includes(item.tag);
        });
      }
      return false;
    });
  },
  cmsPerksPost: (state: ICmsState) => {
    return Object.keys(state.cmsPerksPosts).length
      ? state.cmsPerksPosts
      : null;
  },
};

const mutations = {
  ['SET_CMS_PAGE'](state: ICmsState, payload: ICmsPage[]) {
    state.cmsPages = payload;
  },
  ['RESET_CMS_PAGE'](state: ICmsState) {
    state.cmsPages = [];
  },
  ['SET_CMS_IMPRINT'](state: ICmsState, payload: CmsSingleType) {
    state.cmsImprint = payload;
  },
  ['RESET_CMS_IMPRINT'](state: ICmsState) {
    state.cmsImprint = null;
  },
  ['SET_CMS_FAQ_TENANT'](state: ICmsState, payload: ICmsFAQ[]) {
    state.cmsFAQTenant = payload;
  },
  ['SET_CMS_FAQ_LANDLORD'](state: ICmsState, payload: ICmsFAQ[]) {
    state.cmsFAQLandlord = payload;
  },
  ['SET_CMS_BLOG_POSTS'](state: ICmsState, payload: ICmsBlogPost[]) {
    state.cmsBlogPosts = payload;
  },
  ['RESET_CMS_BLOG_POSTS'](state: ICmsState) {
    state.cmsBlogPosts = [];
  },
  ['SET_CMS_BLOG_POST'](state: ICmsState, payload: ICmsBlogPost) {
    state.cmsBlogPost = payload;
  },
  ['RESET_CMS_BLOG_POST'](state: ICmsState) {
    state.cmsBlogPost = {};
  },
  ['SET_CMS_PERKS_POSTS'](state: ICmsState, payload) {
    state.cmsPerksPosts = payload;
  },
};

const actions: ActionTree<ICmsState, RootState> = {
  receiveCmsPages: ({commit, getters}, payload: ICmsQueryParams) => {
    commit('SET_LOADING', {[String(payload.identifier)]: true});
    return CmsApiService.receiveCmsCollectionType(CmsRoute.PAGES, {
      ...getters.cmsQueryParams,
      ...payload
    })
      .then((data) => {
        if (data.length) {
          commit('SET_CMS_PAGE', data);
        } else {
          router.push({name: 'not-found', params: {errorCode: 'under-construction'}});
        }
      })
      .finally(() => commit('SET_LOADING', {[String(payload.identifier)]: false}));
  },
  receiveCmsImprint: ({commit, getters}) => {
    commit('SET_LOADING', {cmsImprint: true});
    commit('RESET_CMS_IMPRINT');
    return CmsApiService.receiveCmsSingleType(CmsRoute.IMPRINT, getters.cmsQueryParams)
      .then((data) => commit('SET_CMS_IMPRINT', data))
      .finally(() => commit('SET_LOADING', {cmsImprint: false}));
  },
  receiveCmsFAQTenant: ({commit, getters}, queryParams: object) => {
    commit('SET_LOADING', {'cms-faq-tenant': true});
    return CmsApiService.receiveCmsCollectionType(CmsRoute.FAQ_TENANT, {
        ...getters.cmsQueryParams,
        ...queryParams ?? ''
      }
    )
      .then((data: ICmsFAQ[]) => {
        commit('SET_CMS_FAQ_TENANT', data);
        return data;
      })
      .finally(() => commit('SET_LOADING', {'cms-faq-tenant': false}));
  },
  receiveCmsFAQLandlord: ({commit, getters}) => {
    commit('SET_LOADING', {'cms-faq-landlord': true});
    return CmsApiService.receiveCmsCollectionType(CmsRoute.FAQ_LANDLORD, getters.cmsQueryParams)
      .then((data) => commit('SET_CMS_FAQ_LANDLORD', data))
      .finally(() => commit('SET_LOADING', {'cms-faq-landlord': false}));
  },
  receiveCmsBlogPosts: ({commit, getters}) => {
    commit('SET_LOADING', {'cms-blog-posts': true});
    return CmsApiService.receiveCmsCollectionType(CmsRoute.BlOG_POSTS, getters.cmsQueryParams)
      .then((data) => commit('SET_CMS_BLOG_POSTS', data))
      .finally(() => commit('SET_LOADING', {'cms-blog-posts': false}));
  },
  resetCmsBlogPosts: ({commit}) => {
    commit('RESET_CMS_BLOG_POSTS');
  },
  receiveCmsBlogPost: ({commit, getters}, payload: ICmsQueryParams) => {
    commit('SET_LOADING', {'cms-blog-post': true});
    commit('RESET_CMS_BLOG_POST');
    const params = {
      ...getters.cmsQueryParams,
      ...payload
    };
    return CmsApiService.receiveCmsCollectionType(CmsRoute.BlOG_POSTS, params)
      .then((data) => {
        if (data.length) {
          commit('SET_CMS_BLOG_POST', data[0]);
        } else {
          router.push({name: 'blog'});
        }
      })
      .catch(() => router.push({name: 'not-found'}))
      .finally(() => commit('SET_LOADING', {'cms-blog-post': false}));
  },
  receiveCmsPerksPosts: ({commit, getters}) => {
    commit('SET_LOADING', {'cms-perks-posts': true});
    return CmsApiService.receiveCmsCollectionType(CmsRoute.PERKS, getters.cmsQueryParams)
      .then((data) => {
        commit('SET_CMS_PERKS_POSTS', data);
      })
      .finally(() => commit('SET_LOADING', {'cms-perks-posts': false}));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
