




import {Component, Vue} from 'vue-property-decorator';
import {VueConstructor} from 'vue';
import {layoutDictionary} from '@/dictionaries/layout.dictionary';
import {ILayout, Language} from '@/interfaces';
import {AdaptiveMode, BrowserMode} from '@/store/modules/ui.store';
import {i18n} from '@/main';
import {debounce} from '@/utilities/debounce';
import {EnvProvider} from '@/utilities/EnvProvider';

@Component({
  metaInfo() {
    return {
      title: EnvProvider('VUE_APP_OG_TITLE'),
      titleTemplate: `%s | ${EnvProvider('TITLE')}`,
      meta: [
        { name: 'description', content: EnvProvider('VUE_APP_OG_DESCRIPTION') },
        {
          property: 'og:title',
          content: i18n.tc('home.meta.title'),
          template: (chunk) => `${chunk} | ${EnvProvider('TITLE')}`,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: i18n.tc('home.meta.description'),
          template: (chunk) => `${chunk}`,
          vmid: 'og:description'
        },
        {property: 'og:image', content: EnvProvider('URL') + require('@/assets/images/openGraph/main.png'), vmid: 'og:image'},
        {property: 'og:image:width', content: '890', vmid: 'og:image:width'},
        {property: 'og:image:height', content: '600', vmid: 'og:image:height'},
        {property: 'og:type', content: 'website'},
        {property: 'og:locale', content: this.$store.getters.language},
        {property: 'og:locale:alternate', content: Language.EN},
        {property: 'og:locale:alternate', content: Language.DE},
        {property: 'og:site_name', content: EnvProvider('TITLE')},
      ]
    };
  },
})
export default class App extends Vue {
  public resizeDebounceTimeout = 25;

  public created() {
    this.detectBrowserMode();
    this.resizeHandler();
  }

  public mounted() {
    window.addEventListener(
      'resize',
      debounce(this.resizeHandler, this.resizeDebounceTimeout),
      { passive: true },
    );
  }

  public beforeDestroy() {
    window.removeEventListener(
      'resize',
      this.resizeHandler,
    );
  }

  public get layout(): VueConstructor | null {
    return this.$route.name
      ? layoutDictionary.filter((item: ILayout) => {
          return item.name === (this.$route.meta!.layout || 'default');
        })[0].component
      : null;
  }

  public resizeHandler() {
    const viewPort = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.$store.commit('SET_VIEW_PORT', viewPort);

    if (viewPort < 768) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.XS) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.XS);
      }
    } else if (viewPort < 1200) {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.SM) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.SM);
      }
    } else {
      if (this.$store.state.ui.adaptiveMode !== AdaptiveMode.MD) {
        this.$store.commit('SET_ADAPTIVE_MODE', AdaptiveMode.MD);
      }
    }
  }

  public detectBrowserMode(): void {
    const isBrowserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.$store.commit(
      'SET_BROWSER_MODE',
      isBrowserDarkMode ? BrowserMode.DARK : BrowserMode.LIGHT
    );
  }
}
