type scrollBehavior = 'smooth' | 'auto';

export const scrollTo = (selector: string, behavior?: scrollBehavior) => {
  const element = document.querySelector(selector);
  if (element !== null) {
    element.scrollIntoView({behavior: behavior || 'auto'});
  }
};

export const scrollToHash = (hash: string): void => {
  const node = document.querySelector(
    `${hash}`
  ) as HTMLElement;

  return node
    ? node.scrollIntoView({behavior: 'smooth'})
    : console.warn('Node is not defined');
};
