import { EnvProvider } from '@/utilities/EnvProvider';

interface IStaticOptions {
  version?: string;
  local?: boolean;
}

const defaultOptions = {
  version: 'v1',
  local: false
};

export const getStatic = (
  path: string,
  options?: IStaticOptions
): string => {
  options = {...defaultOptions, ...options};
  path = path[0] === '/' ? path.replace('/', '') : path;

  return options.local
    ? `${EnvProvider('URL_STATIC_LOCAL')}/${options.version}/${path}`
    : `${EnvProvider('URL_STATIC')}/${options.version}/${path}`;
};
