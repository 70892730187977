import dotenv from 'dotenv';

dotenv.config();

import envVars from '../env.json';

export const EnvProvider = (name: string) => {
  if (!(name in envVars)) { return; }
  
  const value = envVars[name];
  if (!value) { return; }
  
  return value.startsWith('$VUE_APP_')
    ? process.env[value.substr(1)] || undefined
    : value;
};
