










































import { Component, Vue } from 'vue-property-decorator';
import { Language, LanguageNameEnum } from '@/interfaces';
import { getIconByLanguage } from '@/dictionaries/icons.language.dictionary';
import { ILocaleSettings } from '@/services/api/dictionary.service';

@Component
export default class LanguageCountrySwitcher extends Vue {
  public getIconByLanguage = getIconByLanguage;
  public languageName = LanguageNameEnum;
  private isSwitcherActive = false;

  private onItemClick(item: ILocaleSettings, language: Language) {
    this.$store.dispatch('setLocalization', {
      country: item.country.toLocaleLowerCase(),
      language,
    });
    this.$store.commit('SET_CURRENT_SETTINGS', item);
  }
}
