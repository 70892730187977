import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import {getField, updateField} from 'vuex-map-fields';
import {Language} from '@/interfaces';
import router from '@/router';
import { LocaleService } from '@/utilities/locale';
import { i18n } from '@/main';

export enum AdaptiveMode {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export enum BrowserMode {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export interface ILoadingData {
  [key: string]: boolean;
}

export interface IUiState {
  adaptiveMode: AdaptiveMode;
  browserMode: BrowserMode;
  loadingDictionary: ILoadingData;
  uniformLoadingDictionary: ILoadingData;
  viewPort: number;
}

const initialState = (): IUiState => ({
  adaptiveMode: AdaptiveMode.MD,
  browserMode: BrowserMode.LIGHT,
  loadingDictionary: {},
  uniformLoadingDictionary: {},
  viewPort: 0,
});
const state = initialState();

const getters = {
  getUiFields: (state: IUiState) => getField(state),
  isLoading: (state: IUiState) => Object.keys(state.loadingDictionary)
    .map((key: string) => state.loadingDictionary[key])
    .some((isLoading: boolean) => isLoading),
  isUniformLoading: (state: IUiState) => {
    return (key: string) => key in state.uniformLoadingDictionary && state.uniformLoadingDictionary[key];
  },
  isMobileAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.XS,
  isTabletAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.SM,
  isDesktopAdaptiveMode: (state: IUiState) => state.adaptiveMode === AdaptiveMode.MD,
  isBrowserDarkMode: (state: IUiState) => state.browserMode === BrowserMode.DARK,
  browserDarkModeSuffix: (state: IUiState, getters) => getters.isBrowserDarkMode ? '-dark' : '',
  isLocale: () => (locale: keyof typeof Language) => LocaleService.getSeparatedByCode(i18n.locale).lang === locale,
};

const mutations = {
  updateUiFields: (state: IUiState, payload: boolean) => updateField(state, payload),

  ['SET_ADAPTIVE_MODE'](state: IUiState, payload: AdaptiveMode) {
    state.adaptiveMode = payload;
  },
  ['SET_BROWSER_MODE'](state: IUiState, payload: BrowserMode) {
    state.browserMode = payload;
  },
  ['SET_LOADING'](state: IUiState, payload: ILoadingData) {
    state.loadingDictionary = {...state.loadingDictionary, ...payload};
  },
  ['SET_UNIFORM_LOADING'](state: IUiState, payload: ILoadingData) {
    state.uniformLoadingDictionary = {...state.uniformLoadingDictionary, ...payload};
  },
  ['SET_VIEW_PORT'](state: IUiState, payload: number) {
    state.viewPort = payload;
  },
};

const actions: ActionTree<IUiState, RootState> = {
  goBack: (_) => {
    const isRedirectNeeded = window.history.length <= 2;

    isRedirectNeeded
      ? router.push({name: 'home'})
      : router.go(-1);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
