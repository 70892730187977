













import {Component, Vue} from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class Button extends Vue {
  public get getClass() {
    return {
      'transparent': this.$attrs.transparent
    };
  }

  private get attrs() {
    return {
      ...this.$attrs,
      'background-color': 'white'
    };
  }
}
