import { ActionTree, GetterTree } from 'vuex';
import store, { RootState } from '@/store';
import DictionaryApiService, {
  DictionaryNameType,
  ICommonDictionary,
  ILocaleSettings
} from '@/services/api/dictionary.service';
import { Country } from '@/interfaces';

export interface IDictionaryState {
  dictionaries: ICommonDictionary[];
  localeSettings: null | ILocaleSettings[];
  currentSettings: null | ILocaleSettings;
  availableCountries: null | Country[];
}

export interface ISelectItem {
  value: string|number;
  text: string|number|string[]|number[]|boolean|boolean[]|null;
}

const initialState = (): IDictionaryState => ({
  dictionaries: [],
  localeSettings: null,
  currentSettings: null,
  availableCountries: null,
});

const state = initialState();

const getters: GetterTree<IDictionaryState, RootState> = {
  getDictionary: (state: IDictionaryState) => {
    return (name: DictionaryNameType) => {
      const result: ICommonDictionary | undefined = state.dictionaries
        .find((dictionary: ICommonDictionary) => dictionary.name === name);
      return result ? result.values : {};
    };
  },
  getSelectListFromDictionary: (state: IDictionaryState, getters) => {
    return (name: DictionaryNameType): ISelectItem[] => {
      const dictionary = Object.keys(getters.getDictionary( name )).length
        ? getters.getDictionary( name )
        : null;
      return dictionary
        ? Object.keys(dictionary).map((item: string) => ({
            value: item,
            text: dictionary[item][store.getters.language]
          }))
        : [];
    };
  },
  customSelectLabel: (state: IDictionaryState, getters) => {
    return (value: string, name: DictionaryNameType) => {
      return Object.keys(getters.getDictionary( name )).length
        ? getters.getDictionary( name )[value]
          ? getters.getDictionary( name )[value][store.getters.language]
          : getters.getDictionary( name )[value]
        : '';
    };
  },
  getLocaleSettings: (state: IDictionaryState) => state.localeSettings,
  getCurrentSettings: (state: IDictionaryState) => state.currentSettings,
  getAvailableCountries: (state: IDictionaryState) => state.availableCountries,
};

const mutations = {
  ['SET_DICTIONARY'](state: IDictionaryState, payload: ICommonDictionary) {
    if (!state.dictionaries.some((item) => item.name === payload.name)) {
      state.dictionaries.push(payload);
    }
  },
  ['SET_SETTINGS'](state: IDictionaryState, payload: ILocaleSettings[]) {
    state.localeSettings = payload;
  },
  ['SET_CURRENT_SETTINGS'](state: IDictionaryState, payload: ILocaleSettings) {
    state.currentSettings = payload;
  },
  ['SET_AVAILABLE_COUNTRIES'](state: IDictionaryState, payload: Country[]) {
    state.availableCountries = payload;
  }
};

const actions: ActionTree<IDictionaryState, RootState> = {
  getDictionary: ({commit}, dictionary: DictionaryNameType) => {
    if (!state.dictionaries.some((item) => item.name === dictionary)) {
      commit('SET_UNIFORM_LOADING', {[dictionary]: true});
      return DictionaryApiService.getDictionary(dictionary)
        .then((data: ICommonDictionary) => commit('SET_DICTIONARY', data))
        .finally(() => commit('SET_UNIFORM_LOADING', {[dictionary]: false}));
    }
  },
  localeSettings: ({ commit }) => {
    commit('SET_LOADING', {'locale-settings': true});
    return DictionaryApiService.getLocaleSetting()
      .then((response: {settings: ILocaleSettings[]}) => {
        commit('SET_SETTINGS', response.settings);
        commit(
          'SET_CURRENT_SETTINGS',
          response.settings.find((item) => item.country === store.getters.country)
        );
        commit(
          'SET_AVAILABLE_COUNTRIES',
          Array.from(response.settings, (item) => item.country)
        );
      })
      .finally(() => commit('SET_LOADING', {'locale-settings': false}));
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
