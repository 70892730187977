import { Vue, Component } from 'vue-property-decorator';

import {EnvProvider} from '@/utilities/EnvProvider';
import {replace} from '@/utilities/getPath';

@Component
export default class UrlWithParams extends Vue {
  public mediumLink: string = EnvProvider('MEDIUM');
  public youtubeLink: string = EnvProvider('YOUTUBE');
  public instaLink: string = EnvProvider('INSTAGRAM');
  public facebookLink: string = EnvProvider('FACEBOOK');
  public linkedinLink: string = EnvProvider('LINKEDIN');
  public replace = replace;

  public replaceDomain(url): string {
    return url.indexOf('.com') === -1
      ? `${url}.${this.$store.getters.country}`
      : url.replace('.com', `.${this.$store.getters.country}`);
  }
  public get onboardingLandlordUrl(): string {
    const language = this.$store.getters.language;
    return `${this.replaceDomain(EnvProvider('ONBOARDING_LANDLORD_URL'))}/${language}`;
  }

  public get landlordAppUrl(): string {
    const language = this.$store.getters.language;
    return `${this.replaceDomain(EnvProvider('LANDLORD_URL'))}/${language}`;
  }

  public get tenantAppUrl(): string {
    const language = this.$store.getters.language;
    return `${this.replaceDomain(EnvProvider('TENANT_URL'))}/${language}`;
  }

  public get getInsuranceProductHash(): string {
    return this.$store.getters.getCurrentSettings
      ? this.$store.getters.getCurrentSettings.insuranceProduct.residential
      : null;
  }

  public get onboardingTenantUrl(): string | null {
    const language = this.$store.getters.language;
    return `${this.replaceDomain(EnvProvider('ONBOARDING_TENANT_URL'))}/${language}/choose-country`;
  }
}
