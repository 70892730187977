






































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    HeaderNavigation: () => import('@/components/Layout/Header/Header.navigation.vue'),
    HeaderActions: () => import('@/components/Layout/Header/Header.actions.vue'),
    HeaderMenuMobile: () => import('@/components/Layout/Header/Header.menu.mobile.vue'),
  },
})
export default class Header extends Vue {
  private isMobileMenu: boolean = false;
}
