import { getStatic } from '@/utilities/static';
import _Vue from 'vue';

export default {
  install: (Vue: typeof _Vue) => {
    Vue.prototype.$getStatic = getStatic;
  }
};

declare module 'vue/types/vue' {
  interface Vue {
    $getStatic: (path: string) => string;
  }
}
