import Vue from 'vue';
import VueRouter, {RawLocation, Route, RouteConfig} from 'vue-router';
import {scrollTo, scrollToHash} from '@/utilities';
import { RouterPathEnum } from '@/interfaces';
import VueMeta from 'vue-meta';
import { localeGuard } from '@/router/guards/localeGuard';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const routes: Array<RouteConfig> = [
  {
    path: '/:country(de|at)/:lang(de|en)',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(
          /* webpackChunkName: "HomePage" */
          '@/pages/Home/Home.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.BUSINESSES}`,
        name: 'businesses',
        component: () => import(
          /* webpackChunkName: "BusinessPage" */
          '@/pages/Business/Business.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.LANDLORDS}`,
        name: 'landlords',
        component: () => import(
          /* webpackChunkName: "BusinessPage" */
          '@/pages/Landlords/Landlords.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.KNOW_YOUR_RENTER}`,
        name: 'know-your-renter',
        component: () => import(
          /* webpackChunkName: "KnowYourRenterPage" */
          '@/pages/KnowYourRenter/KnowYourRenter.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.ABOUT}`,
        name: 'about-us',
        component: () => import(
          /* webpackChunkName: "AboutUsPage" */
          '@/pages/AboutUs/AboutUs.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.IMPRINT}`,
        name: 'imprint',
        component: () => import(
          /* webpackChunkName: "ImprintPage" */
          '@/pages/Imprint/Imprint.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.CONTACT}`,
        name: 'contact-us',
        component: () => import(
          /* webpackChunkName: "ContactPage" */
          '@/pages/Contact/Contact.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.AGENTS}`,
        name: 'agents',
        component: () => import(
          /* webpackChunkName: "AgentsPage" */
          '@/pages/Agents/Agents.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.GIVEBACK}`,
        name: 'giveback',
        component: () => import(
          /* webpackChunkName: "GivebackPage" */
          '@/pages/Giveback/Giveback.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.CAREER}`,
        name: 'career',
        component: () => import(
          /* webpackChunkName: "CareerPage" */
          '@/pages/Career/Career.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.NEWSROOM}`,
        name: 'newsroom',
        component: () => import(
          /* webpackChunkName: "NewsroomPage" */
          '@/pages/Newsroom/Newsroom.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.FAQ}`,
        name: 'faq',
        component: () => import(
          /* webpackChunkName: "FAQPage" */
          '@/pages/FAQ/FAQ.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.TESTIMONIES}`,
        name: 'testimonies',
        props: () => ({ errorCode: 'under-construction' }),
        component: () => import(
          /* webpackChunkName: "ErrorPage" */
          '@/pages/Error/Error.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.CLAIMS}`,
        name: 'claims',
        component: () => import(
          /* webpackChunkName: "ClaimsPage" */
          '@/pages/Claims/Claims.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.HOMECARE}`,
        name: 'home-care',
        component: () => import(
          /* webpackChunkName: "HomeCarePage" */
          '@/pages/HomeCare/HomeCare.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.REFER}`,
        name: 'refer-a-friend',
        component: () => import(
          /* webpackChunkName: "ReferFriendPage" */
          '@/pages/ReferFriend/ReferFriend.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.B2B}`,
        name: 'B2B-partner',
        component: () => import(
          /* webpackChunkName: "B2BPage" */
          '@/pages/B2BPartner/B2BPartner.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.AFFILIATE}`,
        name: 'affiliate-program',
        props: () => ({ errorCode: 'under-construction' }),
        component: () => import(
          /* webpackChunkName: "AffiliatePage" */
          '@/pages/Affiliate/Affiliate.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.PRIVACY}`,
        name: 'privacy',
        component: () => import(
          /* webpackChunkName: "PrivacyPage" */
          '@/pages/Privacy/Privacy.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.TERMS}`,
        name: 'terms',
        component: () => import(
          /* webpackChunkName: "TermsPage" */
          '@/pages/Terms/Terms.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.INFORMATION}`,
        name: 'information',
        component: () => import(
          /* webpackChunkName: "InformationPage" */
          '@/pages/Information/Information.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.LICENSES}`,
        name: 'licenses',
        component: () => import(
          /* webpackChunkName: "LicensesPage" */
          '@/pages/Licenses/Licenses.page.vue'
          ),
      },
      {
        path: `:path${RouterPathEnum.BLOG}`,
        component: {
          render: (c) => c('router-view'),
        },
        children: [
          {
            path: '',
            name: 'blog',
            component: () => import(
              /* webpackChunkName: "BlogPage" */
              '@/pages/Blog/Blog.page.vue'
              ),
          },
          {
            path: ':id',
            name: 'blog-post',
            component: () => import(
              /* webpackChunkName: "BlogPostPage" */
              '@/pages/Blog/Blog.post.page.vue'
              ),
          },
        ]
      },
      {
        path: `:path${RouterPathEnum.PERKS}`,
        name: 'perks',
        component: () => import(
          /* webpackChunkName: "PerksPage" */
          '@/pages/Perks/Perks.page.vue'
          )
      },
      {
        path: 'under-construction',
        name: 'under-construction',
        props: () => ({ errorCode: 'under-construction' }),
        component: () => import(
          /* webpackChunkName: "ErrorPage" */
          '@/pages/Error/Error.page.vue'
          ),
      },
      {
        path: 'test-server-error',
        name: 'test-server-error',
        component: () => import(
          /* webpackChunkName: "TestServerError" */
          '@/pages/Error/ServerErrorTest.vue'
          ),
      },

      // Frame layout
      {
        path: 'widget',
        name: 'widget',
        meta: { layout: 'frame' },
        component: () => import(
          /* webpackChunkName: "WidgetPage" */
          '@/pages/Frame/Widget/Widget.page.vue'
          ),
      },
      {
        path: 'landing',
        name: 'landing',
        meta: { layout: 'frame' },
        component: () => import(
          /* webpackChunkName: "WidgetPage" */
          '@/pages/Frame/Landing/Landing.page.vue'
          ),
      },
      {
        path: 'ui',
        name: 'ui',
        meta: { layout: 'ui' },
        component: () => import(
          /* webpackChunkName: "UiPage" */
          '@/pages/Ui/Ui.page.vue'
          ),
      },
      {
        path: 'link-expired',
        name: 'link-expired',
        props: () => ({ errorCode: 'link-expired' }),
        component: () => import(
          /* webpackChunkName: "ErrorPage" */
          '@/pages/Error/Error.page.vue'
          ),
        meta: {
          analyticsIgnore: true
        }
      },
      {
        path: 'oops',
        name: 'oops',
        component: () => import(
          /* webpackChunkName: "OktaError" */
          '@/pages/Error/OktaError.vue'
          ),
      },
      {
        path: '*',
        name: 'not-found',
        props: (route) => ({ errorCode: route.params.errorCode || 404 }),
        component: () => import(
          /* webpackChunkName: "ErrorPage" */
          '@/pages/Error/Error.page.vue'
          ),
        meta: {
          analyticsIgnore: true
        }
      },
      {
        path: '/404',
        name: '404',
        props: () => ({ errorCode: 404 }),
        component: () => import(
          /* webpackChunkName: "Error400" */
          '@/pages/Error/Error.page.vue'
          ),
      },
      {
        path: '/500',
        name: '500',
        props: () => ({ errorCode: 500 }),
        component: () => import(
          /* webpackChunkName: "Error500" */
          '@/pages/Error/Error.page.vue'
          ),
      },

    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to: Route) => {
    return to.hash
      ? scrollToHash(to.hash)
      : scrollTo('#app');
  },
});

// checking url and transforming if it's needed
router.beforeEach((to, from, next) => localeGuard(to, from, next));

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      resolve(this.currentRoute);
    }, (error) => {
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export default router;
