import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { getField, updateField } from 'vuex-map-fields';
import UserService from '@/services/api/user.service';
import userService, { IAgentForm, IContactForm, ILicenseForm, IUserForm } from '@/services/api/user.service';
import { Country, Language } from '@/interfaces';
import router from '@/router';
import { getCountry, getLang } from '@/plugins/vue-i18n';
import { i18n } from '@/main';

export interface ILanguageParams {
  language: Language;
  country: Country;
  preferences: boolean;
}

export interface IUserState {}

const initialState = (): IUserState => ({});
const state = initialState();

const getters = {
  getUserFields: (state: IUserState) => getField(state),
  language: () => getLang(),
  country: () => getCountry(),
};

const mutations = {
  updateUserFields: (state: IUserState, payload: boolean) => updateField(state, payload),
};

const actions: ActionTree<IUserState, RootState> = {
  test: () => userService.test(),
  setLocalization: (_, { language, country }: ILanguageParams) => {
    router.currentRoute.name === 'blog-post'
      ? router.push({name: 'blog', params: {lang: language, country}})
      : router.push({params: {
          path: i18n.tc(`routes.${router.currentRoute.name}`),
          lang: language,
          country
      }});
  },
  sendUserForm: ({commit}, data: IUserForm) => {
    commit('SET_LOADING', {sendUserForm: true});
    return UserService.sendUserForm(data)
      .finally(() => commit('SET_LOADING', {sendUserForm: false}));
  },
  sendAgentForm: ({commit}, data: IAgentForm) => {
    commit('SET_LOADING', {sendAgentForm: true});
    return UserService.sendAgentForm(data)
      .finally(() => commit('SET_LOADING', {sendAgentForm: false}));
  },
  sendContactForm: ({commit}, data: IContactForm) => {
    commit('SET_LOADING', {sendContactForm: true});
    return UserService.sendContactForm(data)
      .finally(() => commit('SET_LOADING', {sendContactForm: false}));
  },
  sendPartnerForm: ({commit}, data: IAgentForm) => {
    commit('SET_LOADING', {sendPartnerForm: true});
    return UserService.sendPartnerForm(data)
      .finally(() => commit('SET_LOADING', {sendPartnerForm: false}));
  },
  sendLicenseForm: ({commit}, data: ILicenseForm) => {
    commit('SET_LOADING', {sendLicenseForm: true});
    return UserService.sendLicenseForm(data)
      .finally(() => commit('SET_LOADING', {sendLicenseForm: false}));
  },
  sendWidgetForm: ({commit}, data: IContactForm) => {
    commit('SET_LOADING', {sendWidgetForm: true});
    return UserService.sendWidgetForm(data)
      .finally(() => commit('SET_LOADING', {sendWidgetForm: false}));
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
