import { AxiosError } from 'axios';
import router from '@/router';

const errorInterceptor = (error: AxiosError) => {
  if (error.response && error.response.status === 409) {
    return Promise.reject(error);
  } else if (error.response && error.response.status) {
    router.push({
      name: 'not-found',
      params: {
        errorCode: String(error.response.status)
      }
    });
  }
  return Promise.reject(error);
};

export default errorInterceptor;
