import {cmsApi} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {
  CmsPageIdentifier,
  ICmsBlogPost,
  ICmsFAQ,
  ICmsImprintPage,
  ICmsPage
} from '@/store/modules/cms.store';
import {LanguageCodeType, ValueOf} from '@/interfaces';

export interface ICmsResponse {
  _id: string;
  __v: number;
  id: string;
  locale: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  localizations: ICmsResponseLocalization[];
}

export interface ICmsResponseLocalization {
  _id: string;
  id: string;
  locale: string;
  published_at: string;
}

export interface ICmsResponseImage {
  _id: string;
  __v: number;
  id: string;
  name: string;
  alternativeText: string;
  caption: string;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  width: number;
  height: number;
  url: string;
  formats: {};
  provider: string;
  related: [];
  createdAt: Date;
  updatedAt: Date;
}

export interface ICmsResponseAdminUser {
  _id: string;
  __v: number;
  id: string;
  username: string|null;
  firstname: string|null;
  lastname: string|null;
  preferedLanguage: LanguageCodeType;
}

export type CmsSortType = 'ASC' | 'DESC';

export type CmsFilterType
  = ''
  | 'eq' // Equal
  | 'ne' // Not equal
  | 'lt' // Less than
  | 'gt' // Greater than
  | 'lte' // Less than or equal to
  | 'gte' // Greater than or equal to
  | 'in' // Included in an array
  | 'nin' // 	Not included in an array
  | 'contains' // Contains
  | 'ncontains' // Doesn't contain
  | 'containss' // Contains, case sensitive
  | 'ncontainss' // Doesn't contain, case sensitive
  | 'null'; // Is null or not null

export interface ICmsQueryParams {
  _filters?: CmsFilterType;
  _sort?: CmsSortType;
  _sortByName?: string;
  _limit?: number;
  _start?: number;
  _locale?: string;
  identifier?: ValueOf<CmsPageIdentifier>;
  id?: string;
}

export enum CmsRoute {
  PAGES = 'pages',
  IMPRINT = 'imprint',
  FAQ_TENANT = 'faq-tenants',
  FAQ_LANDLORD = 'faq-landlords',
  BlOG_POSTS = 'blog-posts',
  PERKS = 'perks',
}

export type CmsCollectionType
  = ICmsPage[]
  | ICmsFAQ[]
  | ICmsBlogPost[];
export type CmsSingleType = ICmsImprintPage;

export interface ICmsApiService {
  receiveCmsCollectionType: (route: ValueOf<CmsRoute>, params: ICmsQueryParams) => Promise<CmsCollectionType>;
  receiveCmsSingleType: (route: ValueOf<CmsRoute>, params: ICmsQueryParams) => Promise<CmsSingleType>;
}

const receiveCmsCollectionType = (route: ValueOf<CmsRoute>, params: ICmsQueryParams): Promise<CmsCollectionType> =>
  cmsApi.get(
    `/${route}`,
    { params }
  )
  .then((response: AxiosResponse<CmsCollectionType>) => response.data)
  .catch((error) => Promise.reject(error));

const receiveCmsSingleType = (route: ValueOf<CmsRoute>, params: ICmsQueryParams): Promise<CmsSingleType> =>
  cmsApi.get(
    `/${route}`,
    { params }
  )
  .then((response: AxiosResponse<CmsSingleType>) => response.data)
  .catch((error) => Promise.reject(error));

const CmsApiService: ICmsApiService = {
  receiveCmsCollectionType,
  receiveCmsSingleType
};

export default CmsApiService;
