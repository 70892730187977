import { ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import FrameApiService, {IFrameLandingData} from '@/services/api/frame.service';

export interface IFrameState {
  landingData: Partial<IFrameLandingData>;
}

const initialState = (): IFrameState => ({
  landingData: {}
});

const state = initialState();

const getters: GetterTree<IFrameState, RootState> = {

};

const mutations = {

};

const actions: ActionTree<IFrameState, RootState> = {
  sendFrameLandingData: (_, payload: IFrameLandingData) => {
    return FrameApiService.sendFrameLandingData(payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
