
export const addUnderlineToString = (text: string, count: number = 1, from: 'start'|'end' = 'end') => {
  if (!text.length) { return text; }
  
  const words = text.split(' ');
  
  if (from === 'start') {
    const firstWord = words.slice(0, count);
    const rest = words.slice(count, words.length);
    return [
      `<span class="highlight-underline">${firstWord}</span>`,
      ...rest
    ].join(' ');
  }
  
  if (from === 'end') {
    const lastWord = words.slice(-count);
    const rest = words.slice(0, -count);
    return [
      ...rest,
      `<span class="highlight-underline">${lastWord}</span>`
    ].join(' ');
  }
};
