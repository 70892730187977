import { ActionTree } from 'vuex';
import { RootState } from '@/store';

export type ErrorCode = 404 | 500;

export interface IErrorObject {
  code: ErrorCode;
  message: string;
}

export interface IValidationState {
  errorData: null | IErrorObject;
}

const initialState = (): IValidationState => ({
  errorData: null,
});
const state = initialState();

const getters = {
  getErrorData: (state: IValidationState) => state.errorData
};

const mutations = {
  ['SET_ERROR_DATA'](state: IValidationState, payload) {
    state.errorData = payload;
  },
};

const actions: ActionTree<IValidationState, RootState> = {
};

export default {
  state,
  getters,
  mutations,
  actions
};
