import {IIcons} from '@/interfaces';

import CheckboxIcon from '@/assets/icons/checkbox.svg';
import CheckboxCheckedIcon from '@/assets/icons/checkbox-checked.svg';
import RadioIcon from '@/assets/icons/radio-button.svg';
import RadioCheckedIcon from '@/assets/icons/radio-button-checked.svg';
import closeIcon from '@/assets/icons/close-icon.svg';
import menuIcon from '@/assets/icons/menu-icon.svg';
import fbIcon from '@/assets/icons/fb-icon.svg';
import linkedinIcon from '@/assets/icons/linkedin-icon.svg';
import instagramIcon from '@/assets/icons/insta-icon.svg';
import mediumIcon from '@/assets/icons/medium-icon.svg';
import youTubeIcon from '@/assets/icons/youtube-icon.svg';
import emailIcon from '@/assets/icons/email-icon.svg';
import copyIcon from '@/assets/icons/copy-icon.svg';
import informationIcon from '@/assets/icons/information.svg';
import starFull from '@/assets/icons/star-full.svg';
import starEmpty from '@/assets/icons/star-empty.svg';
import starHalf from '@/assets/icons/star-half.svg';

export const ICONS: IIcons = {
  radio: {
    component: RadioIcon,
  },
  radioChecked: {
    component: RadioCheckedIcon,
  },
  checkbox: {
    component: CheckboxIcon,
  },
  checkboxChecked: {
    component: CheckboxCheckedIcon,
  },
  closeIcon: {
    component: closeIcon,
  },
  menuIcon: {
    component: menuIcon,
  },
  fbIcon: {
    component: fbIcon,
  },
  linkedinIcon: {
    component: linkedinIcon,
  },
  instagramIcon: {
    component: instagramIcon,
  },
  mediumIcon: {
    component: mediumIcon,
  },
  youtubeIcon: {
    component: youTubeIcon,
  },
  emailIcon: {
    component: emailIcon,
  },
  copyIcon: {
    component: copyIcon,
  },
  informationIcon: {
    component: informationIcon,
  },
  starFull: {
    component: starFull,
  },
  starEmpty: {
    component: starEmpty,
  },
  starHalf: {
    component: starHalf,
  },
};
