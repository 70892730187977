




















import { Component } from 'vue-property-decorator';
import FooterBottomNavigation from '@/components/Layout/Footer/Footer.bottom.navigation.vue';
import UrlWithParams from '@/mixines/urlWithParams.mixin';
import {ISocialLinksList} from '@/interfaces';

@Component({
  components: { FooterBottomNavigation }
})

export default class FooterBottom extends UrlWithParams {
  private get getSocials(): ISocialLinksList[] {
    return [
      {
        icon: '$mediumIcon',
        link: this.mediumLink,
        label: 'social link medium',
      },
      {
        icon: '$youtubeIcon',
        link: this.youtubeLink,
        label: 'social link youtube',
      },
      {
        icon: '$instagramIcon',
        link: this.instaLink,
        label: 'social link instagram',
      },
      {
        icon: '$fbIcon',
        link: this.facebookLink,
        label: 'social link facebook',
      },
      {
        icon: '$linkedinIcon',
        link: this.linkedinLink,
        label: 'social link linkedin',
      },
    ];
  }
}
