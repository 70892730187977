import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import {userType} from '@/interfaces';

export interface IUserForm {
  companyName: string;
  rentDepositAmount: null | number;
  businessSector: string;
  legalForm: string;
  firstName: string;
  lastName: string;
  email: string;
  newsAgreement: boolean;
  phoneNumber: {
    number: string;
    dialCode: string;
  };
}

export interface IAgentForm {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  newsAgreement: boolean;
  phoneNumber: {
    number: string;
    dialCode: string;
  };
}

export interface IContactForm {
  firstName: string;
  lastName: string;
  phoneNumber: {
    dialCode: string,
    number: string
  };
  email: string;
  userType: userType | string;
  newsAgreement: boolean;
  message: string;
}

export interface ILicenseForm {
  email: string;
  country: string;
}

export interface IUserService {
  sendUserForm: (data: IUserForm) => Promise<null | IUserForm>;
  sendAgentForm: (data: IAgentForm) => Promise<null | IAgentForm>;
  sendContactForm: (data: IContactForm) => Promise<null | IContactForm>;
  sendPartnerForm: (data: IAgentForm) => Promise<null | IAgentForm>;
  sendLicenseForm: (data: ILicenseForm) => Promise<null | ILicenseForm>;
  sendWidgetForm: (data: IContactForm) => Promise<null | IContactForm>;
  test: () => Promise<any>;
}

const test = (): Promise<any> => api
  .get('/test/server-error')
  .catch((error) => Promise.reject(error));

const sendUserForm = (data: IUserForm): Promise<IUserForm> => api
  .post('/website/opportunity', data)
  .then((response: AxiosResponse<IUserForm>) => response.data)
  .catch((error) => Promise.reject(error));

const sendAgentForm = (data: IAgentForm): Promise<IAgentForm> => api
  .post('/website/agent', data)
  .then((response: AxiosResponse<IAgentForm>) => response.data)
  .catch((error) => Promise.reject(error));

const sendContactForm = (data: IContactForm): Promise<IContactForm> => api
  .post('/website/contact', data)
  .then((response: AxiosResponse<IContactForm>) => response.data)
  .catch((error) => Promise.reject(error));

const sendPartnerForm = (data: IAgentForm): Promise<IAgentForm> => api
  .post('/website/business-partner', data)
  .then((response: AxiosResponse<IAgentForm>) => response.data)
  .catch((error) => Promise.reject(error));

const sendLicenseForm = (data: ILicenseForm): Promise<ILicenseForm> => api
  .post('/website/license', data)
  .then((response: AxiosResponse<ILicenseForm>) => response.data)
  .catch((error) => Promise.reject(error));

const sendWidgetForm = (data: IContactForm): Promise<IContactForm> => api
  .post('/website/widget', data)
  .then((response: AxiosResponse<IContactForm>) => response.data)
  .catch((error) => Promise.reject(error));

const UserService: IUserService = {
  test,
  sendUserForm,
  sendAgentForm,
  sendContactForm,
  sendPartnerForm,
  sendLicenseForm,
  sendWidgetForm,
};

export default UserService;
