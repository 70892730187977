import Vue from 'vue';
import Vuex from 'vuex';
import ui, {IUiState} from '@/store/modules/ui.store';
import error, {IValidationState} from '@/store/modules/error.store';
import user, {IUserState} from '@/store/modules/user.store';
import dictionary, {IDictionaryState} from '@/store/modules/dictionary.store';
import frame, {IFrameState} from '@/store/modules/frame.store';
import cms, {ICmsState} from '@/store/modules/cms.store';

Vue.use(Vuex);

export interface RootState {
  ui: IUiState;
  errors: IValidationState;
  user: IUserState;
  dictionary: IDictionaryState;
  frame: IFrameState;
  
  cms: ICmsState;
}

export default new Vuex.Store<RootState>({
  modules: {
    ui,
    error,
    user,
    dictionary,
    frame,
    
    cms,
  },
});
