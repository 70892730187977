import { VueConstructor } from 'vue';

export type ValueOf<T> = T[keyof T];

export interface IDictionary<T> { [key: string]: T; }

export interface ILayout {
  name: string;
  component: VueConstructor;
}

export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
  currentTarget: T;
};

export interface IIcons {
  [key: string]: {
    component: VueConstructor,
    props?: {
      name: string,
    },
  };
}

export type UiColorType = 'default' | 'primary' | 'secondary' | 'tertiary' | 'danger' | 'white';
export type UiSizeType = 'xs' | 'sm' | 'md';

export interface INavigation {
  link: string;
  title: string;
  class?: string;
}

export interface IDescription {
  title?: string|number;
  subtitle?: string|number;
  text?: string|number;
  component?: VueConstructor;
  mobileComponent?: VueConstructor;
  image?: string;
}

export type errorPageTypes = '404' | '500' | 'under-construction' | 'link-expired';

export type userType = 'PRIVATE_TENANT' | 'COMMERCIAL_TENANT' | 'LANDLORD' | 'PROPERTY_MANAGER' | 'OTHER';

export enum usersTypes {
  PRIVATE_TENANT = 'PRIVATE_TENANT',
  COMMERCIAL_TENANT = 'COMMERCIAL_TENANT',
  LANDLORD = 'LANDLORD',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  OTHER = 'OTHER',
}

export interface IUserType {
  value: userType;
  text: string;
}

export enum Role {
  TENANT = 'ROLE_TENANT',
  LANDLORD = 'ROLE_LANDLORD',
}

// Language enum for different languages
export enum Language {
  DE = 'de',
  EN = 'en'
}

export enum Country {
  DE = 'de',
  EN = 'en',
  AT = 'at',
  NL = 'nl',
}

export type LanguageCodeType = ValueOf<Language>;

export enum LanguageNameEnum {
  DE = 'Deutsch',
  EN = 'English'
}

export type LanguageNameType = ValueOf<LanguageNameEnum>;

export interface ILanguage {
  name: LanguageNameType;
  code: LanguageCodeType;
}
export interface ILangCountry {
  text: string;
  country: Country;
  language: Language;
  icon: Country;
  preferences?: boolean;
}
export interface ILicenseCard {
  country: string;
  code?: string;
  authority?: string;
  href?: string;
  image: VueConstructor;
}

export interface ILicenceForm {
  country: string;
  email: string;
}

export enum RouterPathEnum {
  BUSINESSES = '(businesses|gewerbemieter)',
  LANDLORDS = '(landlords|vermieter)',
  KNOW_YOUR_RENTER = '(know-your-renter|know-your-renter)',
  ABOUT = '(about-us|ueber-uns)',
  IMPRINT = '(imprint|impressum)',
  CONTACT = '(contact-us|kontakt)',
  AGENTS = '(agents|makler)',
  GIVEBACK = '(giveback|unser-engagement)',
  CAREER = '(career|karriere)',
  NEWSROOM = '(newsroom)',
  FAQ = '(faq)',
  TESTIMONIES = '(testimonies|kundenstimmen)',
  CLAIMS = '(claims|schadensabwicklung)',
  HOMECARE = '(home-care)',
  REFER = '(refer-a-friend|freunde-werben)',
  B2B = '(B2B-partner)',
  AFFILIATE = '(affiliate-program|affiliate-programm)',
  PRIVACY = '(data-privacy|datenschutzerklaerung)',
  TERMS = '(terms-and-conditions|allgemeine-geschaeftsbedingungen)',
  INFORMATION = '(first-information|erstinformation)',
  LICENSES = '(licenses|lizenzen)',
  BLOG = '(blog)',
  PERKS = '(my-perks|meine-verguenstigungen)',
}

export enum staticDocsEnum {
  FIRST_INFO = 'FIRST_INFO',
  PRODUCT_INFO = 'PRODUCT_INFO',
  CONSULTING_PROTOCOL = 'CONSULTING_PROTOCOL',
  AVB = 'AVB',
  COMPILATION = 'COMPILATION',
  SAMPLE = 'SAMPLE',
}

export enum DocumentNameEnum {
  PRODUCT_INFO = 'product-info',
  AVB = 'avb',
}

export interface ISocialLinksList {
  icon: string;
  link: string;
  label: string;
}

export interface IHeadItem {
  image: string;
  title: string;
  subtitle?: string;
  button?: string;
  href?: string;
}
