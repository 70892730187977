import './router/componentHooks';
import Vue, { VNode } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueI18n from 'vue-i18n';
import en from '@/languages/translations/en';
import Vuetify from 'vuetify/lib/framework';
import Vuebar from 'vuebar';
import Notification from 'vue-notification';
import VueMask from 'v-mask';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
// @ts-ignore
import themeStyles from '@/styles/stylus/variables/colors.styl';
import {ICONS} from '@/dictionaries/icons.dictionary';

import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin';
import InlineSvg from 'vue-inline-svg';

import Fragment from 'vue-fragment';
import Button from '@/components/Ui/Button.vue';
import Input from '@/components/Ui/Input.vue';
import Select from '@/components/Ui/Select.vue';
import Checkbox from '@/components/Ui/Checkbox.vue';
import Radio from '@/components/Ui/Radio.vue';
import {EnvProvider} from '@/utilities/EnvProvider';
import getStatic from '@/plugins/static.plugin';

Vue.config.productionTip = false;

Vue.use(getStatic);
Vue.use(VueI18n);
export const i18n = new VueI18n( {
  locale: 'en-DE',
  fallbackLocale: 'en-DE',
  silentTranslationWarn: true,
  messages: {
    en
  }
});

// Vuetify theme issue: https://github.com/vuetifyjs/vuetify/issues/7932
// MQ switches dark mode of vuetify theme;
const mq = window.matchMedia('(prefers-color-scheme: dark)');
// The changes will be available after reloading page
mq.addEventListener(
  'change',
  (e) => vuetify.framework.theme.dark = e.matches
);

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    default: 'light',
    dark: mq.matches,
    options: {
      customProperties: false,
      variations: false,
      themeCache: {
        // @ts-ignore
        get: (key) => localStorage.getItem(key),
        // @ts-ignore
        set: (key, value) => localStorage.setItem(key, value),
      },
    },
    themes: {
      light: {
        primary: themeStyles.primary,
        secondary: themeStyles.secondary,
        tertiary: themeStyles.tertiary,
        success: themeStyles.successColor,
        error: themeStyles.errorColor,
        warning: themeStyles.warningColor,
        info: themeStyles.infoColor,
        gray: themeStyles.gray,
      },
      dark: {
        primary: themeStyles.primaryDark,
        grey: themeStyles.primaryDark,
        secondary: themeStyles.secondaryDark,
        tertiary: themeStyles.tertiaryDark,
        success: themeStyles.successColor,
        error: themeStyles.errorColor,
        warning: themeStyles.warningColor,
        info: themeStyles.infoColor,
        gray: themeStyles.gray,
      },
    },
  },
  icons: {
    values: ICONS
  },
});

Vue.use(Notification);
Vue.use(Vuebar);
Vue.use(Fragment.Plugin);
Vue.use(VueCookieBot, {
  cookieBotID: '9aae0dae-0879-4009-ba67-fef9cfbd3afd',
  blockingMode: 'none',
  defaultLocale: store.getters.language
});
Vue.use(VueMask);

Vue.component('Button', Button);
Vue.component('Input', Input);
Vue.component('Select', Select);
Vue.component('Checkbox', Checkbox);
Vue.component('Radio', Radio);
Vue.component('SvgInline', InlineSvg);

const render = (createElement: typeof Vue.prototype.$createElement): VNode => createElement(App);

Sentry.init({
  Vue,
  dsn: EnvProvider('SENTRY_DSN'),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [EnvProvider('URL'), /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(EnvProvider('SENTRY_TRACES_SAMPLE_RATE')),
  environment: EnvProvider('ENVIRONMENT'),
  release: EnvProvider('SENTRY_RELEASE'),
  logErrors: true
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render
}).$mount('#app');
