import { NavigationGuard } from 'vue-router';
import {
  getFallbackLocale,
  getStorageLocale,
  loadLanguageAsync,
  SUPPORT_LOCALES
} from '@/plugins/vue-i18n';
import { LocaleService } from '@/utilities/locale';

const excludePaths = ['documents'];
export const localeGuard: NavigationGuard = async (to, from, next) => {
  const paramLocale = LocaleService.getLocaleCode(to.params.lang, to.params.country);
  const storageLocale = getStorageLocale();
  const fallbackLocale = getFallbackLocale();

  /**
   * LOCALE GETTER PRIORITY:
   * 1) Url parameter
   * 2) Language from localStorage
   * 3) i18n fallback locale
   */

  const locale = paramLocale && SUPPORT_LOCALES.includes(paramLocale)
      ? paramLocale
      : SUPPORT_LOCALES.includes(storageLocale)
        ? storageLocale
        : fallbackLocale;

  const params = LocaleService.getSeparatedByCode(locale);

  for (const item of excludePaths) {
    if (to.path.split('/').includes(item)) {
      return loadLanguageAsync(locale).then(() => next());
    }
  }

  return !to.params.country || !to.params.lang
    ? to.path
      ? window.location.pathname = `/${params.country}/${params.lang}${window.location.pathname || '/'}`
      : next({ name: 'home', params })
    : loadLanguageAsync(locale).then(() => next());
};
