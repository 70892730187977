import global from './global.json';
import header from './header.json';
import footer from './footer.json';
import home from './home.json';
import business from './business.json';
import landlords from './landlords.json';
import errors from './errors.json';
import contact from './contact.json';
import validation from './validation.json';
import agents from './agents.json';
import referFriend from './referFriend.json';
import faq from './faq.json';
import banner from './banner.json';
import claims from './claims.json';
import licenses from './licenses.json';
import homeCare from './homeCare.json';
import b2bPartner from './b2bPartner.json';
import aboutUs from './aboutUs.json';
import career from './career.json';
import newsroom from './newsroom.json';
import giveback from './giveback.json';
import affiliate from './affiliate.json';
import testimonies from './testimonies.json';
import meta from './meta.json';
import garentiipass from './garentiipass.json';

// Frame
import frame from './frame.json';

// CMS
import blog from './blog.json';
import perks from './perks.json';

// routes
import routes from './routes.json';

export default {
  ...global,
  ...header,
  ...footer,
  ...home,
  ...business,
  ...landlords,
  ...errors,
  ...contact,
  ...validation,
  ...agents,
  ...referFriend,
  ...faq,
  ...claims,
  ...banner,
  ...licenses,
  ...homeCare,
  ...b2bPartner,
  ...aboutUs,
  ...career,
  ...newsroom,
  ...giveback,
  ...affiliate,
  ...testimonies,
  ...meta,
  ...garentiipass,

  // Frame
  ...frame,
  
  // CMS
  ...blog,
  ...perks,

  // routes
  ...routes,
};
