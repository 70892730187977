export const LocaleService = {
  getSeparatedByCode: (localeCode: string) => {
      const locale = localeCode.toLocaleLowerCase().split('-');
      return {lang: locale[0], country: locale[1]};
  },
  getLocaleCode: (lang?: string, country?: string): string | undefined => {
      return !!lang || !!country
        ? `${lang?.toLocaleLowerCase()}-${country?.toLocaleUpperCase()}`
        : undefined;
  },
};
