
import { Country, Language } from '@/interfaces';
import { getStatic } from '@/utilities/static';

export const IconsCountryDictionary: Record<Country, string> = {
  [Country.DE]: getStatic('icons/flags/de.svg'),
  [Country.EN]: getStatic('icons/flags/en.svg'),
  [Country.AT]: getStatic('icons/flags/at.svg'),
  [Country.NL]: getStatic('icons/flags/nl.svg'),
};

export const getIconByLanguage = (language: Language | Country) => IconsCountryDictionary[language];
