















import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import Header from '@/components/Layout/Header/Header.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';
import Notification from '@/components/Ui/Notification.vue';
import {EnvProvider} from '@/utilities/EnvProvider';

@Component({
  components: {
    Notification,
    Header,
    Footer,
    FooterHomeCareBottom: () => import('@/components/Layout/Footer/Footer.homeCare.bottom.vue'),
    Loading,
  },
})

export default class DefaultLayout extends Vue {
  private beforeCreate(): void {
    this.$store.dispatch('localeSettings');
  }

  private mounted(): void {
    if ('prod' === EnvProvider('ENVIRONMENT')) {
      const headScript = document.createElement('script');
      headScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://gtm.garentii.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TDJRKPX');`;
      const headElement = document.querySelector('head');
      if (headElement) {
        headElement.prepend(headScript);
      }

      const bodyElement = document.querySelector('body');
      if (bodyElement) {
        const iFrameElement = document.createElement('iframe');
        iFrameElement.src = 'https://gtm.garentii.com/ns.html?id=GTM-TDJRKPX';
        iFrameElement.style.display = 'none';
        iFrameElement.style.visibility = 'hidden';
        iFrameElement.height = '0';
        iFrameElement.width = '0';

        const bodyScript = document.createElement('noscript');
        bodyScript.prepend(iFrameElement);
        bodyElement.prepend(bodyScript);
      }

      this.$cookiebot.consentBanner({
        async: true,
        locale: this.$store.getters.language,
      });
    }

    window.addEventListener('CookiebotOnConsentReady', () => {
      this.$store.dispatch(
        'setLocalization',
        {
          language: this.$store.getters.language,
          country: this.$store.getters.country,
        }
      );
    }, false);

    window.addEventListener('CookiebotOnDecline', () => {
      localStorage.removeItem('locale');
    }, false);
  }
}
