import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';

export interface IFrameLandingData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: {
    dialCode: string;
    number: string;
  };
  newsAgreement: boolean;
}

export interface IFrameApiService {
  sendFrameLandingData: (data: IFrameLandingData) => Promise<null>;
}

const sendFrameLandingData = (data: IFrameLandingData): Promise<null> => api
  .post(
    '/website/widget',
    data
  )
  .then((response: AxiosResponse<null>) => response.data)
  .catch((error) => Promise.reject(error));

const FrameApiService: IFrameApiService = {
  sendFrameLandingData
};

export default FrameApiService;
