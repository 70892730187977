


























































import {Component} from 'vue-property-decorator';
import FooterNavigation from '@/components/Layout/Footer/Footer.navigation.vue';
import FooterBottom from '@/components/Layout/Footer/Footer.bottom.vue';
import FooterBottomNavigation from '@/components/Layout/Footer/Footer.bottom.navigation.vue';
import LanguageCountrySwitcher from '@/components/LanguageCountrySwitcher.vue';
import {EnvProvider} from '@/utilities/EnvProvider';
import UrlWithParams from '@/mixines/urlWithParams.mixin';

@Component({
  components: {
    FooterNavigation,
    FooterBottom,
    FooterBottomNavigation,
    LanguageCountrySwitcher,
  }
})

export default class Footer extends UrlWithParams {
  public get navDictionary() {
    return {
      garentii: {
        title: this.$tc('footer.navigation.garentii.title'),
        listItem: [
          {
            title: this.$tc('footer.navigation.garentii.tenants'),
            link: 'home'
          },
          {
            title: this.$tc('footer.navigation.garentii.businesses'),
            link: 'businesses'
          },
          {
            title: this.$tc('footer.navigation.garentii.landlordsAndPropertyManagers'),
            link: 'landlords'
          },
          {
            title: this.$tc('footer.navigation.garentii.garentiipass'),
            link: 'know-your-renter'
          },
          {
            title: this.$tc('footer.navigation.garentii.agents'),
            link: 'agents'
          }
        ]
      },
      company: {
        title: this.$tc('footer.navigation.company.title'),
        listItem: [
          {
            title: this.$tc('footer.navigation.company.about'),
            link: 'about-us'
          },
          {
            title: this.$tc('footer.navigation.company.program'),
            link: 'giveback'
          },
          {
            title: this.$tc('footer.navigation.company.career'),
            link: 'career'
          },
          {
            title: this.$tc('footer.navigation.company.newsroom'),
            link: 'newsroom'
          }
        ]
      },
      customerCare: {
        title: this.$tc('footer.navigation.customerCare.title'),
        listItem: [
          {
            title: this.$tc('footer.navigation.customerCare.FAQs'),
            link: 'faq'
          },
          {
            title: this.$tc('footer.navigation.customerCare.blog'),
            link: 'blog'
          },
          {
            title: this.$tc('footer.navigation.customerCare.testimonies'),
            link: 'testimonies',
            rel: 'nofollow'
          },
          {
            title: this.$tc('footer.navigation.customerCare.claim'),
            link: 'claims'
          },
          {
            title: this.$tc('footer.navigation.customerCare.homeCare'),
            link: 'home-care'
          },
          {
            title: this.$tc('footer.navigation.customerCare.refer'),
            link: 'refer-a-friend'
          },
          {
            title: this.$tc('footer.navigation.customerCare.contact'),
            link: 'contact-us'
          }
        ]
      },
      partnership: {
        title: this.$tc('footer.navigation.partnership.title'),
        listItem: [
          {
            title: this.$tc('footer.navigation.partnership.partner'),
            link: 'B2B-partner'
          },
          {
            title: this.$tc('footer.navigation.partnership.program'),
            link: 'affiliate-program'
          },
        ]
      },
      language: {
        title: this.$tc('footer.navigation.language.title'),
      },
    };
  }

  public get getAppleLink() {
    return EnvProvider(`APPLE_STORE_APP_${this.$store.getters.language.toUpperCase()}`);
  }

  public get getGoogleLink() {
    return EnvProvider(`GOOGLE_STORE_APP_${this.$store.getters.language.toUpperCase()}`);
  }
}
