import axios, {CancelTokenSource, CancelTokenStatic} from 'axios';
import { AxiosRequestConfig } from 'axios';
import {IDictionary} from '@/interfaces';

const requestDictionary: IDictionary<null | CancelTokenSource> = {};
const CancelToken: CancelTokenStatic = axios.CancelToken;
const cancelInterceptor = (config: AxiosRequestConfig) => {
  const id: string = config.url!;
  const existentSource = requestDictionary[id];
  if (existentSource) {
    existentSource.cancel('aborted');
    requestDictionary[id] = null;
  }
  const source = CancelToken.source();
  requestDictionary[id] = source;
  config.cancelToken = source.token;
  return config;
};

export default cancelInterceptor;
