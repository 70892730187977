// @ts-ignore
import { CookieService } from './cookie.service';

export interface ITokenService {
  getToken: () => string | null;
  setToken: (tokenInfo: {value: string; rememberMe: boolean}) => void;
  resetToken: () => void;
}

const cookieToken = CookieService.get('tenantAuthorizationToken');
let token: string | null = (!cookieToken === null || cookieToken === 'null')
  ? null
  : cookieToken;

const getToken = (): string | null => token;
const setToken = (tokenInfo: {value: string; rememberMe: boolean}): void => {
  token = tokenInfo.value;
  tokenInfo.rememberMe
    ? CookieService.set('tenantAuthorizationToken', tokenInfo.value, {path: '/', expires: new Date('Tue, 19 Jan 2038 03:14:07 UTC')})
    : CookieService.set('tenantAuthorizationToken', tokenInfo.value);
};
const resetToken = (): void => {
  CookieService.remove('tenantAuthorizationToken', {path: '/'});
  token = null;
};

const TokenService: ITokenService = {
  getToken,
  setToken,
  resetToken,
};
export default TokenService;
