













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FooterBottomNavigation extends Vue {
  public get listItem() {
    return [
      { title: this.$tc('footer.bottom.imprint'), route: 'imprint' },
      { title: this.$tc('footer.bottom.privacyPolicy'), route: 'privacy' },
      { title: this.$tc('footer.bottom.termsConditions'), route: 'terms' },
      { title: this.$tc('footer.bottom.firstInformation'), route: 'information' },
      { title: this.$tc('footer.bottom.licenses'), route: 'licenses' }
    ];
  }

  @Prop({
    type: String,
    required: false
  })
  public direction!: 'column' | 'row';

  public get classObject() {
    return {
      [this.direction]: !!this.direction
    };
  }
}
