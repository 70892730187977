








import { Component, Vue, Prop } from 'vue-property-decorator';
import {UiColorType, UiSizeType} from '@/interfaces';

@Component({
  inheritAttrs: false,
})
export default class Button extends Vue {
  @Prop({type: String, required: false, default: 'primary'})
  public color!: UiColorType;

  @Prop({type: String, required: false, default: 'md'})
  public size!: UiSizeType;

  @Prop({type: Boolean, required: false})
  public disabled!: false;

  @Prop({type: Boolean, required: false})
  public outlined!: false;

  @Prop({type: Boolean, required: false})
  public transparent!: false;

  public get attrs() {
    return {
      ...this.$attrs,
      ripple: false,
      class: this.classObject,
    };
  }

  public get classObject() {
    return {
      [`button-${this.color}`]: this.color,
      [`button-${this.size}`]: this.size,
      disabled: this.disabled,
      outlined: this.outlined,
      transparent: this.transparent,
    };
  }
}
