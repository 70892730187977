import {api} from '@/services/api/api.service';
import {AxiosResponse} from 'axios';
import { Country, Language, ValueOf } from '@/interfaces';

export type DictionaryNameType
  = 'business-sectors'
  | 'legal-forms'
  | 'europe-countries'
  | 'user-types';

export interface ICommonDictionary {
  name: string;
  values: ICommonDictionaryValues<string, string>;
}

export interface ILocaleSettings {
  country: Country;
  enabled: boolean;
  languages: Language[];
  insuranceProduct: Record<InsuranceProductEnum, string>;
}

// eslint-disable-next-line
export interface ICommonDictionaryValues<Type, Key> {
  [Type: string]: {
    [Key: string]: string;
  };
}

export enum InsuranceProductEnum {
  COMMERCIAL = 'commercial',
  RESIDENTIAL = 'residential',
}

export type InsuranceProductType = ValueOf<InsuranceProductEnum>;

export interface IDictionaryApiService {
  getDictionary: (dictionary: DictionaryNameType) => Promise<ICommonDictionary>;
  getLocaleSetting: () => Promise<{settings: ILocaleSettings[]}>;
}

const getDictionary = (dictionary: DictionaryNameType): Promise<ICommonDictionary> => api
  .get(`/dictionary/${dictionary}`)
  .then((response: AxiosResponse<ICommonDictionary>) => response.data)
  .catch((error) => Promise.reject(error));

const getLocaleSetting = (): Promise<{settings: ILocaleSettings[]}> => api
  .get('/settings')
  .then((response: AxiosResponse<{settings: ILocaleSettings[]}>) => response.data)
  .catch((error) => Promise.reject(error));

const DictionaryApiService: IDictionaryApiService = {
  getDictionary,
  getLocaleSetting,
};

export default DictionaryApiService;
