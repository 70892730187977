











import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Radio extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  public disabled!: false;

  @Prop({
    type: String,
    required: false
  })
  private label!: string;

  public get attrs() {
    return {
      ...this.$attrs,
      disabled: this.disabled,
      class: this.classObject,
    };
  }

  public get classObject() {
    return {
      disabled: this.disabled,
    };
  }
}
